import styled from "@emotion/styled"

export const Topbar = styled.header`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem 2rem;
  z-index: 59;

  img {
    max-width: 50px;
    max-height: 50px;
  }
`
export const MenuButtonLine = styled.div`
  height: 2px;
  margin-bottom: 0.5rem;
  background-color: #fefefe;
  transition: all 0.4s ease-in-out;

  :first-of-type {
    width: 3rem;
  }

  :last-of-type {
    width: 1.5rem;
    margin-bottom: 0;
  }
`

export const MenuButtonLineDark = styled.div`
  height: 2px;
  margin-bottom: 0.5rem;
  background-color: #2497c6;
  transition: all 0.4s ease-in-out;

  :first-of-type {
    width: 3rem;
  }

  :last-of-type {
    width: 1.5rem;
    margin-bottom: 0;
  }
`

export const MenuButton = styled.div`
  transition: all 0.4s ease-in-out;
  position: relative;
  overflow: hidden;

  :hover,
  &.open {
    cursor: pointer;

    ${MenuButtonLine} {
      :first-of-type {
        transform: translateX(-100%);
      }

      :last-of-type {
        transform: translateX(200%);
      }
    }

    :after {
      transform: translate(-50%, -50%);
    }
  }

  :after {
    display: block;
    content: "menu";
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fefefe;
    transform: translate(-50%, 100%);
    transition: all 0.4s ease-in-out;
  }
`

export const MenuButtonDark = styled.div`
  transition: all 0.4s ease-in-out;
  position: relative;
  overflow: hidden;

  :hover,
  &.open {
    cursor: pointer;

    ${MenuButtonLineDark} {
      :first-of-type {
        transform: translateX(-100%);
      }

      :last-of-type {
        transform: translateX(200%);
      }
    }

    :after {
      transform: translate(-50%, -50%);
    }
  }

  :after {
    display: block;
    content: "menu";
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: inherit;
    transform: translate(-50%, 100%);
    transition: all 0.4s ease-in-out;
  }
`
